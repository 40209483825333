<template>
  <PageHeader
    image="header_10"
    withMobile
    :title="$lang('title')"
    :breadcrumbs="$lang('breadcr')"
  />
  <!-- section1 -->
  <section class="pt-8 pb-5 bg-blue4">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h3 class="fw-semibold text-blue2 mb-4">
            {{ $lang("section1.title") }}
          </h3>
          <h5 class="fw-regular">
            {{ $lang("section1.text") }}
          </h5>
        </div>
      </div>
    </div>
  </section>
  <!-- section2 -->
  <section id="button-container" class="bg-blue4 py-4">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 mb-2 mb-lg-0" v-for="(s, i) of $lang('sections')" :key="i">
          <button
            @click="goToId(parseId(s.title))"
            class="btn btn-outline-blue2 btn-block text-center"
          >
            {{ s.title }}
          </button>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-blue4 py-4 button-container-fixed" v-if="fixedButtons">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 mb-2 mb-lg-0" v-for="(s, i) of $lang('sections')" :key="i">
          <button
            @click="goToId(parseId(s.title))"
            class="btn btn-outline-blue2 btn-block text-center"
          >
            {{ s.title }}
          </button>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-blue4 pt-5 pb-9">
    <div class="container">
      <div class="row mb-6" v-for="(s, i) of $lang('sections')" :id="parseId(s.title)" :key="i">
        <div class="col-12 mb-4">
          <h2 class="text-green1">{{ s.title }}</h2>
        </div>
        <div class="col-12">
          <Accordion :data="s.items" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Accordion from "@/components/Accordion.vue";

export default {
  components: {
    PageHeader,
    Accordion,
  },
  data: () => ({
    fixedButtons: false,
    navOffset: null,
  }),
  mounted() {
    this.navOffset = document.getElementById("button-container").offsetTop;
    document.addEventListener("scroll", this.watchNav);
  },
  beforeUnmount() {
    document.removeEventListener("scroll", this.watchNav);
  },
  methods: {
    parseId(title) {
      return title.toLowerCase().split(" ").join("_");
    },
    watchNav() {
      const currentScroll = window.scrollY; // get current position

      this.fixedButtons = currentScroll >= this.navOffset;
    },
    goToId(id) {
      const divTop = document.getElementById(id).offsetTop;
      const offset = document.getElementById("button-container").offsetHeight + 20;

      window.scroll(0, divTop - offset);
    },
  },
};
</script>
